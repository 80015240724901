import {FaFacebook, FaInstagram, FaTiktok} from "react-icons/fa";
import styles from './footer.module.css';
import logowhite from '../assets/goodlogowhite.png';
import {useNavigate, useParams} from "react-router-dom";

function Footer() {

    const navigate = useNavigate();
    const {id } = useParams();
    const handleImageLoad = (event) => {
        event.target.classList.add('loaded'); // Add the 'loaded' class to trigger fade-in
    };
    const handleSocialClick = (link) => () => {
        window.open(link, '_blank');
    };

    const navigateRoot = () => {
        if (id!=null){
            navigate("/" + id);
        }
    };

    return (
        <div className={styles.footerMainContainer}>
            <div className={styles.footerSubContainer}>
                <a href="https://faismoibg.com/" target="_blank"><img onClick={navigateRoot} onLoad={handleImageLoad} className="white fade-in"  src={logowhite} alt="fmbg" style={{marginTop:"14px", width:"100px", height:"31px", cursor:"pointer"}}/></a>
                <div className={styles.logoRow}>
                    <a href="https://www.facebook.com/faismoibg" target="_blank"><FaFacebook style={{ fontSize: '1.5rem', cursor:"pointer", color:"white"}}/></a>
                    <a href="https://www.instagram.com/faismoibg/" target="_blank"><FaInstagram style={{ fontSize: '1.6rem', marginRight:"18px", marginLeft:"18px", cursor:"pointer", color:"white" }}/></a>
                    <a href="https://www.tiktok.com/@faismoibg" target="_blank"><FaTiktok style={{ fontSize: '1.3rem',cursor:"pointer", color:"white"}}/></a>
                </div>
                <div className={styles.copyrightCol}>
                    <span style={{fontWeight:"300", fontSize:"14px"}}>Copyright © 2025 Faismoibg</span>
                </div>
            </div>
        </div>
    );
}

export default Footer;