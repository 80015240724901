import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getStorage, ref, uploadBytes } from "firebase/storage";
import { httpsCallable, getFunctions } from 'firebase/functions';
import { v4 as uuidv4 } from 'uuid';
import {getDownloadURL} from "@firebase/storage";
import imageCompression from "browser-image-compression";


const firebaseConfig = {
    apiKey: "AIzaSyC5IvOGDbAqMO-T-Q78RVIqHXNku8vdDD4",
    authDomain: "faismoibg-app.firebaseapp.com",
    projectId: "faismoibg-app",
    storageBucket: "faismoibg-app.appspot.com",
    messagingSenderId: "1062102549014",
    appId: "1:1062102549014:web:52e129f8f395ed04c62b37",
    measurementId: "G-YF0ZB2Y2X4"
};

const firebaseApp = initializeApp(firebaseConfig);



// Initialize Firebase
export const firebaseAuth = getAuth(firebaseApp);
export const firebaseStorj = getStorage(firebaseApp)
// Get the Cloud Functions instance
const functions = getFunctions(firebaseApp);
export const handleImageUpload = async (file) => {
    if (file) {
        const supportedFormats = ['image/jpeg', 'image/png', 'image/jpg'];
        let shouldCompress = false;
        if (supportedFormats.includes(file.type)) {
            shouldCompress=true;
        }
        const compressedFile = shouldCompress ? await imageCompression(file, {
            maxSizeMB: 2, // Adjust the maximum size as needed
            maxWidthOrHeight: 1920, // Adjust the maximum width or height as needed
            useWebWorker: true,
        }) : file;
        const imageRef = ref(firebaseStorj,'forms/'+uuidv4());
        try {
            await uploadBytes(imageRef, compressedFile);
            const url = await getDownloadURL(imageRef);
            return url;
        } catch (error) {
            console.log('Error uploading image:', error);
        }
    }
};


// Cloud functions exports
export const loadHome = httpsCallable(functions, 'loadHome');
export const getBloc = httpsCallable(functions, 'getBloc');
export const getPrest = httpsCallable(functions, 'getPrest');
export const getAgn = httpsCallable(functions, 'getAgn');
export const getFreeTimeSlotsv2cl = httpsCallable(functions, 'getFreeTimeSlotsv2cl');
export const getFreeTimeSlotsv2clbreak = httpsCallable(functions, 'getFreeTimeSlotsv2clbreak');
export const updateRdv = httpsCallable(functions, 'updateRdv');
export const updateDepositRdv = httpsCallable(functions, 'updateDepositRdv');
export const depTempToRdv = httpsCallable(functions, 'depTempToRdv');
export const createCalendarEvent = httpsCallable(functions, 'createCalendarEventC');
export const getRdv = httpsCallable(functions, 'getRdv');
export const cancelRdv = httpsCallable(functions, 'cancelRdv');
export const cancelEvent = httpsCallable(functions, 'cancelEvent');
export const remTempRes = httpsCallable(functions, 'remTempRes');
export const tempDepRecov = httpsCallable(functions, 'tempDepRecov');
export const checkTpDep = httpsCallable(functions, 'checkTpDep');